<template>
  <b-card title="Notes commerciales (Pipedrive)">
    <app-timeline
      style="height:250px;overflow:scroll;padding:10px;"
    >

      <app-timeline-item
        v-if="pipedrive"
        v-for="log in pipedrive"
        :key="log.id"
        variant="info"
        :title="log.contenu"
        :time="timeSince((new Date(log.date)).getTime())"
      />
    </app-timeline>
  </b-card>
</template>

<script>
import axios from '@axios'
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    userData: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pipedrive: [],
    }
  },
  methods: {
    timeSince(date) {
      const seconds = Math.floor((new Date() - date) / 1000)

      let interval = seconds / 31536000

      if (interval > 1) {
        return `Il y a ${Math.floor(interval)} ans`
      }
      interval = seconds / 2592000
      if (interval > 1) {
        return `Il y a ${Math.floor(interval)} mois`
      }
      interval = seconds / 86400
      if (interval > 1) {
        return `Il y a ${Math.floor(interval)} jours`
      }
      interval = seconds / 3600
      if (interval > 1) {
        return `Il y a ${Math.floor(interval)} heures`
      }
      interval = seconds / 60
      if (interval > 1) {
        return `Il y a ${Math.floor(interval)} minutes`
      }
      return `Il y a ${Math.floor(interval)} secondes`
    },
  },
  created() {
    axios
      .get(`https://app2.oscar-black.com/api/pipedrive/${this.userData}`)
      .then(response => {
        this.pipedrive = response.data
        setTimeout(() => { this.ready = true }, 500)
      })
      .catch(() => {})
  },
}
</script>

<style>

</style>
