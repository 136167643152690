<template>
  <b-card title="Drive de l'utilisateur">
    <section>
      <b-container v-if="ready">
        <b-row class="mb-2">
          <div v-if="!loading" style="cursor:pointer;" @click.prevent="downloadall()">
            <feather-icon
              icon="DownloadIcon"
              size="18"
              class="mr-1"
            />Tout télécharger
          </div>
          <b-spinner v-if="loading" label="Téléchargement..." />
        </b-row>
        <b-row style="height:600px;overflow:scroll;">
          <b-col
            v-for="message in drives"
            :key="message.id"
            sm="12"
            md="3"
            lg="3"
          >
            <b-card class="text-left" style="border: 0.5px solid grey; -moz-box-shadow: 3px 3px 5px 6px #ccc; -webkit-box-shadow: 3px 3px 5px 6px #ccc; box-shadow: 3px 3px 5px 6px #ccc;">
              <h5 class="text-center">#{{ message.id }}. {{ message.title }}</h5>
              <hr/>
              <div
                class="d-flex justify-content-start align-items-center mb-1"
              >
                <div class="profile-user-info">
                  <small class="text-muted">Date : {{ (new Date(message.date*1000)).getDate() }}/{{ (new Date(message.date*1000)).getMonth() }}/{{ (new Date(message.date*1000)).getFullYear() }} à {{ (new Date(message.date*1000)).getHours() }}:{{ (new Date(message.date*1000)).getMinutes() }}</small>
                </div>
              </div>

              <video
                style="width:100%;"
                v-if="message.file != null && !message.file.includes('vidyard') && message.file !== '' && message.file.includes('.mp4') && !message.file.includes('.m4v') && !message.file.includes('.mov') && !message.file.includes('.docx') && !message.file.includes('.xls') && !message.file.includes('.xlsx')"
                controls>
                <source
                  :src="`https://app2.oscar-black.com/uploads/visuels/${message.file}`"
                  class="rounded mb-50"
                >
              </video>
              <pdf v-else-if="message.file != null && message.file !== '' && message.file.includes('.pdf')"
              :src="`https://app2.oscar-black.com/uploads/visuels/${message.file}`"></pdf>
                <!-- post video -->
              <div v-else-if="message.file != null && message.file !== '' && !message.file.includes('.pdf') && !message.file.includes('.m4v') && !message.file.includes('.mov') && !message.file.includes('.docx') && !message.file.includes('.xls') && !message.file.includes('.xlsx')"
              v-viewer
              >
                <img style="width:100%;" :src="`https://app2.oscar-black.com/uploads/visuels/${message.file}`"  />
              </div>




              <!-- <video
                style="width:100%;"
                v-if="message.file != null && !message.file.includes('vidyard') && message.file !== '' && message.file.includes('.mp4') && !message.file.includes('.m4v') && !message.file.includes('.mov') && !message.file.includes('.docx') && !message.file.includes('.xls') && !message.file.includes('.xlsx')"
                controls>
                <source
                  :src="`https://app2.oscar-black.com/uploads/visuels/${message.file}`"
                  class="rounded mb-50"
                >
              </video>
              <b-embed
                v-else-if="message.file != null && message.file !== '' && !message.file.includes('.m4v') && !message.file.includes('.mov') && !message.file.includes('.docx') && !message.file.includes('.xls') && !message.file.includes('.xlsx')"
                type="iframe"
                :src="`https://app2.oscar-black.com/uploads/visuels/${message.file}`"
                allowfullscreen

                class="rounded mb-50"
              /> -->
              <hr/>
              <b-card-text>
                {{ message.message }}
              </b-card-text>
              <div class="text-center">
                <a target="_blank" :href="`https://app2.oscar-black.com/uploads/visuels/${message.file}`">
                  <feather-icon
                    class="mr-1 ml-1"
                    icon="EyeIcon"
                    size="18"
                  />
                </a>
                <feather-icon
                  class="mr-1 ml-1"
                  style="cursor:pointer"
                  @click="downloadone(message.file)"
                  icon="DownloadIcon"
                  size="18"
                />
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </b-card>
</template>

<script>
import axios from '@axios'
import {
  BCard, BRow, BCol, BButton, VBToggle, BCarousel, BCarouselSlide, BEmbed, BAvatar, BAvatarGroup, BFormFile, BCardText, BSpinner, BInputGroup, BFormInput, BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import 'viewerjs/dist/viewer.css'
import pdf from 'vue-pdf'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    /* eslint-disable vue/no-unused-components */
    VBToggle,
    BCarousel,
    BCarouselSlide,
    BEmbed,
    BAvatar,
    BAvatarGroup,
    BFormFile,
    BCardText,
    BSpinner,
    BInputGroup,
    BFormInput,
    BContainer,
    ToastificationContent,
    pdf,
  },
  props: {
    userData: {
      type: Number,
      required: true,
    },
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      drives: [],
      ready: false,
      encours: null,
      edit: null,
      file: null,
      loading: false,
    }
  },
  methods: {
    editEnCours(val) {
      this.edit = val
    },
    async downloadone(val) {
      await axios
        .get(`https://app2.oscar-black.com/uploads/visuels/${val}`, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = val
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((error) => {console.log(error)})

    },
    async downloadall() {
      this.loading = true
      await axios
        .get(`https://app2.oscar-black.com/api/driveadmin/downloadall/${this.userData}`, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'coucou.zip'
          link.click()
          URL.revokeObjectURL(link.href)
          this.loading = false
        })
        .catch((error) => {console.log(error)})
    },
  },
  created() {
    axios
      .get(`https://app2.oscar-black.com/api/driveadmin/${this.userData}`)
      .then(response => {
        this.drives = response.data
        setTimeout(() => { this.ready = true }, 500)
      })
      .catch(() => {})
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  #sidebar-right {
    width: 550px !important;
  }
</style>
