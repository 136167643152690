<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'admin'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <b-tabs content-class="mt-1">
        <b-tab title="Infos clients" active>
          <user-view-user-info-card :user-data="userData" />
        </b-tab>
        <b-tab title="Messages">
          <user-view-user-message :user-data="userData.id" />
        </b-tab>
        <b-tab title="Drive">
          <user-view-user-drive :user-data="userData.id" />
        </b-tab>
        <b-tab title="Historique client">
          <user-view-user-timeline-card :user-data="userData" />
        </b-tab>
        <b-tab title="Facturation">
          <invoice-list :user-data="userData" />
        </b-tab>
        <b-tab title="Notes sur le client (Pipedrive)">
          <pipedrive :user-data="userData.pipedrive" />
        </b-tab>

      </b-tabs>



    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BTab, BTabs,
} from 'bootstrap-vue'
import InvoiceList from './InvoiceList.vue'
import Pipedrive from './Pipedrive.vue'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'

import UserViewUserMessage from './UserViewUserMessage.vue'
import UserViewUserDrive from './UserViewUserDrive.vue'


export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTab,
    BTabs,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserMessage,
    UserViewUserDrive,


    InvoiceList,
    Pipedrive,
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => { userData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
    }
  },
}
</script>

<style>

</style>
